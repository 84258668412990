<template>
  <div class="project">
    <v-card elevation="2" color="ele" outlined shaped class="mb-5">
      <v-card-title class="primary--text">{{ project.name }}<v-spacer /> [{{ project.identifier }}]</v-card-title>
      <v-card-text>{{ project.projectType && project.projectType.name }} - {{ project.projectPhase && project.projectPhase.name }}</v-card-text>
    </v-card>
    <v-card elevation="2" color="ele" outlined shaped class="mb-5" v-if="project.seller">
      <v-card-title class="primary--text">{{ project.seller.name }} ({{ project.seller.organisation }})</v-card-title>
      <v-card-text> {{ project.seller.email }} <br />{{ project.seller.phone }}</v-card-text>
    </v-card>
    <v-card elevation="2" color="ele" outlined shaped class="mb-5">
      <v-card-title class="primary--text">{{ $t("project.files.title") }}</v-card-title>

      <v-list color="ele" dense>
        <v-list-item-group v-model="selectedFile">
          <v-list-item v-for="(item, i) in project.files" :key="i" @click="openFile(item, project)">
            <v-list-item-icon>
              <v-icon>{{ files[item.extension] || "mdi-file-outline" }}</v-icon>
            </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-actions><Upload :onUpdate="loadPorject" :identifier="project.identifier" /></v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin, formMixin } from "@/helpers/mixins";
import { mapGetters } from "vuex";

import Upload from "./fileupload.vue";

export default {
  name: "project",

  mixins: [formatMixin, listMixin, formMixin],

  components: {
    Upload,
  },

  data() {
    return {
      project: {},
      selectedFile: [],
      files: {
        ".html": "mdi-language-html5",
        ".js": "mdi-nodejs",
        ".json": "mdi-json",
        ".md": "mdi-markdown",
        ".pdf": "mdi-file-pdf",
        ".png": "mdi-file-image",
        ".txt": "mdi-file-document-outline",
        ".xls": "mdi-file-excel",
      },
    };
  },

  props: {},

  computed: {
    ...mapGetters([]),
  },

  created() {
    this.loadPorject();
  },

  methods: {
    loadPorject: function () {
      axios
        .get(`/api/app/projects/${this.$route.params.id}`)
        .then((proj) => {
          this.project = proj.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openFile: function (item, project) {
      let routeUrl = this.$router.resolve({ path: "/api/app/images", query: { identifier: project.identifier, filename: item.name } });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
