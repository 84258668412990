<template>
  <form @submit.prevent="handleSubmit" class="formblock">
    <v-file-input
      ref="fl"
      type="file"
      accept="image/*"
      capture="camera"
      prepend-icon="mdi-camera"
      @change="uploadFile"
      multiple
      :placeholder="$t('fileUpload.filefield.placeholder')"
    />
    <v-btn class="float-right" @click="handleSubmit">Upload</v-btn>
  </form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "FileUpload",

  mixins: [],

  data() {
    return {
      files: null,
    };
  },

  props: {
    onUpdate: {
      type: Function,
    },
    identifier: {
      type: String,
    },
  },

  computed: {
    ...mapGetters([]),
  },

  created() {},

  methods: {
    uploadFile: function (files) {
      this.files = files;
    },
    handleSubmit: function () {
      if (this.files && this.files.length) {
        const formData = new FormData();
        formData.append("identifier", this.identifier);
        this.files.forEach((f) => {
          formData.append("files", f, f.name);
        });
        axios.post("/api/app/images", formData, {}).then((res) => {
          this.onUpdate(res);
          this.files = [];
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formblock {
  width: 100%;
}
</style>
